import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../img/logo2.svg";

const History = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const apiBaseUrl = process.env.REACT_APP_API_URL;

    // Step 1: Define columnDisplayNames for custom header display
    const columnDisplayNames = {
        "Mapping Value": "Voter ID",
        "ELECTION DATE": "Election Date",
        "ELECTION NAME": "Election Name",
        "ELECTION TYPE": "Election Type",
        FIRST_NAME: "First Name",
        MIDDLE_NAME: "Middle Name",
        LAST_NAME: "Last Name",
        SUFFIX: "Suffix",
        "POLITICAL PARTY": "Political Party",
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const mappingValue = searchParams.get("mappingValue");
        if (mappingValue) {
            fetchData(mappingValue);
        }
    }, [location]);

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/");
    };

    const handleBackClick = () => {
        navigate("/search");
    };

    const handleDownloadClick = () => {
        const searchParams = new URLSearchParams(location.search);
        const mappingValue = searchParams.get("mappingValue");
        window.location.href = `${apiBaseUrl}/api/v1/download-history?mappingValue=${mappingValue}`;
    };

    const fetchData = async (mappingValue) => {
        try {
            const response = await axios.get(
                `${apiBaseUrl}/api/v1/voter-history?mappingValue=${mappingValue}`,
                {
                    headers: { Authorization: localStorage.getItem("token") },
                }
            );
            if (response.data.length > 0) {
                setColumns(Object.keys(response.data[0]));
                setData(response.data);
            }
        } catch (error) {
            console.error("Error fetching voter history:", error);
        }
    };

    return (
        <div className="table-container">
            <button
                onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("searchFilters");
                    window.location.href = "/";
                }}
                className="header-button logout-button">
                Logout
            </button>
            <button
                onClick={() => {
                    window.location.href = "/charts";
                }}
                className="header-button charts-button">
                Charts
            </button>
            <div className="header">
                <img src={logo} alt="Voter Data Logo" className="logo" />
                <div className="pill-container">
                    <span className="pill total-entries">
                        Records: {data.length}
                    </span>
                    <button className="back-button" onClick={handleBackClick}>
                        ◀ Back
                    </button>
                </div>
            </div>
            <table>
                <thead>
                    <tr className="controls-row">
                        <th colSpan={columns.length + 1}>
                            <div className="controls">
                                <button
                                    className="download-button"
                                    onClick={handleDownloadClick}>
                                    DOWNLOAD CSV
                                </button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>ROW</th>
                        {columns.map((column) => (
                            <th key={column}>
                                {/* Step 2: Use custom display name if available */}
                                {columnDisplayNames[column] ||
                                    column.replace(/_/g, " ")}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map((row, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                {columns.map((column) => (
                                    <td key={column}>{row[column]}</td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={columns.length + 1}
                                className="no-results">
                                NO RESULTS
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default History;
